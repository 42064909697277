#not-found-page {
  width: 250px;
  text-align: center;
}

#not-found-page > * {
  font: bold 50px Segoe UI;
  margin-top: 150px;
}

#not-found-page a {
  text-decoration: none;
}