table#build-report {
  margin: auto;
  width: auto;
  border: lightgrey;
}

.pc-header:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -1px;
  border-bottom: 2px solid black;
}

.build-header:after {
  content: '';
  position: absolute;
  left: 200px;
  width: calc(100% - 200px);
  bottom: 37px;
  border-bottom: 1px solid lightgrey;
}

table#build-report thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

th.very-special-cell {
  min-width: 200px;
  z-index: 11;
  background-color: white;
  top: 0;
  left: 0;
  position: sticky;
  box-shadow: inset -1px 0 0 0 lightgrey;
}

th.very-special-cell input{
  font-size: 12px;
}

.result {
  height: 25px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 1px;
  cursor: pointer;
}

.result.result-success {
  background-color: palegreen;
}

.result.result-fail {
  background-color: lightcoral;
}

.result.result-skipped {
  background-color: moccasin;
}

.result.result-crashed {
  background-color: firebrick;
}

.result.result-unknown {
  background-color: grey;
}

th.test-name-cell {
  max-width: 200px;

  font-size: 13px;
  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  position: sticky;
  left: 0px;
  background-color: white;
  z-index: 9;

  box-shadow: inset -1px 0 0 0 lightgrey;

  padding: 2px 2px 2px 8px;
}

.test-name-cell > img {
  height: 12px;
  width: 12px;
  display: inline-block;
  margin-right: 5px;
}

.test-name-cell > .name {
  max-width: 100px;
  text-overflow: ellipsis;
  display: inline-block;
}



.test-name-cell > .tags {
  min-height: 15px;
  font-size: 11px;
}

.report-cell {
  padding: 0px !important;
  vertical-align: middle !important;
  text-align: center !important;
  line-height: 0px !important;
  z-index: 1;
}

.pc-header {
  max-width: 100px;

  vertical-align: middle !important;
  text-align: center !important;
  font-size: 13px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.hovered {
  background-color: rgb(0, 0, 0, 0.05);
}

#build-report-container {
  overflow: auto;
  max-height: calc(100vh - 50px);
}

.build-result-filter-params-section {
  display: inline-block;
  vertical-align: top;
  padding-right: 15px;
}

.build-report-filter-modal {
  max-width: 600px;
  width: 600px;
}

.modal-body {
  max-height: 700px;
  overflow-y: auto;
}

.columns {
   max-height: 250px;
   columns: 110px auto;
   overflow: auto;
}

.adv-filter-content label {
  display: block;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
