.user-panel {
  align-items: center;
}

.user-panel > img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: inline-block;
  margin-bottom: 18px;
  margin-right: 5px;
}

.user-panel > div {
  display: inline-block;
}

.user-panel > div > a:first-child {
  display: block;
  padding-top: 10px;
}

.user-panel > div > a:last-child {
  display: block;
  padding-top: 10px;
  font-size: 10px;
  padding-top: 0px;
}