.file-drop-form-container {
  margin-top: 20px;
}

.file-drop-form-container > form > * {
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
}

.file-drop-input-container {
  border: 1px dashed #1fb264;
  border-radius: 5px;
  position: relative;
  height: 100px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.file-drop-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.file-drop-input-container:hover {
  background-color: beige;
}

.cancel-link {
  position: relative;
  text-decoration: none;
}

.cancel-link:hover {
  position: relative;
  text-decoration: underline;
}