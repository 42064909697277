#builds-page {
  width: fit-content;
}

#builds-page > div {
  width: 275px;
  display: inline-block;
  padding: 5px 5px 5px 5px;
  margin: 5px 5px 5px 5px;
  border-right: 1px dotted lightgray;
}

#builds-page > div:last-child {
  border-right: none;
}

#builds-page .build-details {
  font-size: 11px;
}

#builds-page td {
  padding-bottom: 10px;
}

#builds-page td b {
  padding-right: 5px;
  font-size: 18px;
}
